import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import BlogRollPaginated from '../components/BlogRollPaginated';
import Layout from '../components/Layout';
import Section from '../components/Section';
import NewsletterSignupForm from '../components/NewsletterSignupForm';
import BlogPage from './BlogPage';

const BlogPageTemplate = ({ data, pageContext }) => {
  const { frontmatter: page } = data.blogPage;
  const { edges: blogPosts } = data.blogPosts;

  const heroImage = page[defaultLocale].image;
  const title = page[pageContext.locale].title || page[defaultLocale].title;

  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <BlogPage title={title} heroImage={heroImage} />
        <BlogRollPaginated items={blogPosts} />
        <Section>
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

BlogPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogPageTemplate;

export const blogPageQuery = graphql`
  query BlogPage($id: String!, $locale: String!) {
    blogPage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        en {
          title
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        de {
          title
        }
      }
    }
    blogPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: { collection: { eq: "blog-posts" }, locale: { eq: $locale } }
        frontmatter: { title: { ne: "" } }
        rawMarkdownBody: { ne: "" }
      }
    ) {
      edges {
        node {
          ...BlogRollItem
        }
      }
    }
  }
`;
