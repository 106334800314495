import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import LocaleContext from '../context/localeContext';
import BlogRoll from '../components/BlogRoll';
import { ArrowLeftIcon, ArrowRightIcon } from '../components/Icon';
import Button from '../components/Button';
import Section from '../components/Section';

function BlogRollPaginated({ items, templateFilters, pageSize = 6 }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [templateFilter, setTemplateFilter] = useState();
  const [keywordFilter, setKeywordFilter] = useState('');
  const { locale } = useContext(LocaleContext);
  const allowedTemplates = templateFilters?.map((filter) => filter.value);

  const filteredItems = useMemo(() => {
    let filteredItems = items;
    if (templateFilter) {
      filteredItems = filteredItems.filter(
        (item) => item.node.frontmatter.templateKey === templateFilter
      );
    }
    if (keywordFilter) {
      var index = window.__FLEXSEARCH__.en.index;
      var store = window.__FLEXSEARCH__.en.store;
      if (!keywordFilter || !index) {
        return [];
      } else {
        var results = [];
        Object.keys(index).forEach((idx) => {
          results.push(...index[idx].values.search(keywordFilter));
        });
        results = Array.from(new Set(results));
        results = store
          .filter((node) => node.node.locale === locale)
          .filter((node) => allowedTemplates.includes(node.node.templateKey))
          .filter((node) => results.includes(node.id))
          .map((node) => node.node.id);
      }
      filteredItems = filteredItems.filter((item) =>
        results.includes(item.node.id)
      );
    }
    return filteredItems;
  }, [items, locale, allowedTemplates, templateFilter, keywordFilter]);

  const numPages = Math.ceil(filteredItems.length / pageSize);

  const itemsOnPage = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return filteredItems.slice(firstPageIndex, lastPageIndex);
  }, [filteredItems, currentPage, pageSize]);

  function prevPage() {
    setCurrentPage(currentPage - 1);
  }

  function nextPage() {
    setCurrentPage(currentPage + 1);
  }

  function toPage(n) {
    setCurrentPage(n);
  }

  function addTemplateFilter(template) {
    setTemplateFilter(template);
    setCurrentPage(1);
  }

  function removeTemplateFilter() {
    setTemplateFilter(null);
    setCurrentPage(1);
  }

  function clearFilters() {
    removeTemplateFilter();
    setKeywordFilter('');
    setCurrentPage(1);
  }

  return (
    <>
      {templateFilters && (
        <Section colorScheme="white" overlayLength="large" overlaySide="left">
          <div className="container pt-8 md:pt-16 mg: px-16 lg:px-48">
            <span className="inline-block text-xl mb-2">Filter by:</span>
            <input
              type="text"
              className="form-input block w-full rounded-xl bg-transparent mb-2"
              placeholder="Search Keyword"
              value={keywordFilter}
              onChange={(e) => {
                setKeywordFilter(e.target.value);
              }}
            />
            <div className="pb-8 lg:flex lg:flex-row lg:items-center lg:gap-4">
              {templateFilters.map((filter) =>
                templateFilter === filter.value ? (
                  <Button
                    key={filter.value}
                    text={filter.text}
                    action={() => removeTemplateFilter()}
                    color={filter.color}
                    className={'!block my-4 text-center lg:!inline-block'}
                  />
                ) : (
                  <Button
                    key={filter.value}
                    text={filter.text}
                    action={() => addTemplateFilter(filter.value)}
                    color="white"
                    className={'!block my-4 text-center lg:!inline-block'}
                  />
                )
              )}
              <Button
                text="Clear"
                action={clearFilters}
                isDisabled={!templateFilter && !keywordFilter}
                color="red"
                className={'!block my-6 text-center lg:!inline-block ml-auto'}
              />
            </div>
          </div>
        </Section>
      )}
      <Section overlaySide="left">
        <div className="container py-8">
          <BlogRoll blogPosts={itemsOnPage} />
          <div className="text-center">
            <div className="inline-block m-auto">
              {currentPage !== 1 && (
                <span
                  role="menuitem"
                  tabIndex={0}
                  onKeyPress={prevPage}
                  onClick={prevPage}
                  className="mr-8 py-1 px-1 font-medium cursor-pointer hover:text-primaryHover focus:text-primaryPressed"
                >
                  <ArrowLeftIcon
                    className={'inline-block h-4 w-4 ml-2'}
                    strokeWidth="3"
                  />{' '}
                  Prev
                </span>
              )}
              {Array.from({ length: numPages }, (_, i) => {
                if (numPages === 1) {
                  return <></>;
                }
                if (i + 1 === currentPage) {
                  return (
                    <span
                      key={`pagination-number${i + 1}`}
                      className="mx-1 py-1 px-2 font-medium border-2 border-black rounded-full"
                    >
                      {i + 1}
                    </span>
                  );
                } else {
                  return (
                    <span
                      key={`pagination-number${i + 1}`}
                      role="menuitem"
                      tabIndex={0}
                      onKeyPress={() => toPage(i + 1)}
                      onClick={() => toPage(i + 1)}
                      className="mx-3 py-1 px-1 font-medium cursor-pointer hover:text-primaryHover focus:text-primaryPressed"
                    >
                      {i + 1}
                    </span>
                  );
                }
              })}
              {currentPage !== numPages && numPages > 1 && (
                <span
                  role="menuitem"
                  tabIndex={0}
                  onKeyPress={nextPage}
                  onClick={nextPage}
                  className="ml-8 py-1 px-1 font-medium cursor-pointer hover:text-primaryHover focus:text-primaryPressed"
                >
                  Next{' '}
                  <ArrowRightIcon
                    className={'inline-block h-4 w-4'}
                    strokeWidth="3"
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

BlogRollPaginated.propTypes = {
  items: PropTypes.array.isRequired,
  templateFilters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
  pageSize: PropTypes.number,
};

export default BlogRollPaginated;
