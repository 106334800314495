import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ContrastContext from '../context/contrastContext';

function Button({
  text,
  action,
  className,
  color = 'primary',
  isDisabled = false,
}) {
  const { isHighContrastEnabled } = useContext(ContrastContext);

  // This could be more concise, but any less explicit & Tailwind will JiT it out of existence.
  let colorClasses =
    ' border-transparent bg-primary hover:bg-primaryHover focus:bg-primaryPressed';
  if (isHighContrastEnabled) {
    colorClasses =
      ' border-transparent primaryHC hover:primaryHoverHC focus:primaryPressedHC';
  }

  if (color === 'red') {
    if (isHighContrastEnabled) {
      colorClasses =
        ' border-transparent bg-redHC hover:bg-redHoverHC focus:bg-redPressedHC';
    } else {
      colorClasses =
        ' border-transparent bg-red hover:bg-redHover focus:bg-redPressed';
    }
  } else if (color === 'pink') {
    if (isHighContrastEnabled) {
      colorClasses =
        ' border-transparent bg-pinkHC hover:bg-pinkHoverHC focus:bg-pinkPressedHC';
    } else {
      colorClasses =
        ' border-transparent bg-pink hover:bg-pinkHover focus:bg-pinkPressed';
    }
  } else if (color === 'yellow') {
    if (isHighContrastEnabled) {
      colorClasses =
        ' border-transparent bg-yellowHC hover:bg-yellowHoverHC focus:bg-yellowPressedHC';
    } else {
      colorClasses =
        ' border-transparent bg-yellow hover:bg-yellowHover focus:bg-yellowPressed';
    }
  } else if (color === 'purple') {
    if (isHighContrastEnabled) {
      colorClasses =
        ' border-transparent bg-purpleHC hover:bg-purpleHoverHC focus:bg-purplePressedHC';
    } else {
      colorClasses =
        ' border-transparent bg-purple hover:bg-purpleHover focus:bg-purplePressed';
    }
  } else if (color === 'blue') {
    colorClasses =
      ' border-transparent bg-blue hover:bg-blueHover focus:bg-bluePressed';
  } else if (color === 'green') {
    colorClasses =
      ' border-transparent bg-green hover:bg-greenHover focus:bg-greenPressed';
  } else if (color === 'teal') {
    colorClasses =
      ' border-transparent bg-teal hover:bg-tealHover focus:bg-tealPressed';
  } else if (color === 'white') {
    colorClasses =
      ' border-black bg-white text-black hover:bg-black hover:text-white';
  } else {
    // white
    colorClasses =
      ' border-black bg-white text-black hover:bg-black hover:text-white';
  }

  className = `${
    className == null ? '' : className
  } button inline-block px-12 py-3 rounded-full text-white cursor-pointer border `;

  if (isDisabled) {
    if (isHighContrastEnabled) {
      className += ' bg-gray-500';
    } else if (color === 'red') {
      className += ' bg-redDisabled';
    } else if (color === 'pink') {
      className += ' bg-pinkDisabled';
    } else if (color === 'yellow') {
      className += ' bg-yellowDisabled';
    } else if (color === 'purple') {
      className += ' bg-purpleDisabled';
    } else if (color === 'blue') {
      className += ' bg-blueDisabled';
    } else if (color === 'green') {
      className += ' bg-greenDisabled';
    } else if (color === 'teal') {
      className += ' bg-tealDisabled';
    } else if (color === 'white') {
      className += ' bg-gray-300';
    } else {
      className += ' bg-primaryDisabled';
    }
  } else {
    className += colorClasses;
  }

  if (isDisabled) {
    return <span className={className}>{text}</span>;
  }
  return (
    <span
      className={className}
      role="menuitem"
      tabIndex={0}
      onKeyPress={action}
      onClick={action}
    >
      {text}
    </span>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'red',
    'pink',
    'yellow',
    'purple',
    'blue',
    'green',
    'teal',
    'white',
  ]),
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  color: 'primary',
  isDisabled: false,
};

export default Button;
