import * as React from 'react';
import PropTypes from 'prop-types';

import Hero from '../components/Hero';
import Section from '../components/Section';

const BlogPage = ({ title, heroImage }) => {
  return (
    <>
      <Hero image={heroImage} />
      <Section colorScheme="black">
        <div className="container pt-4 pb-16">
          <h1>
            <span className="bg-theme-underline">{title}</span>
          </h1>
        </div>
      </Section>
    </>
  );
};

BlogPage.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.object.isRequired,
};

export default BlogPage;
